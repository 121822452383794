import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import config from '../config';
import { setCredentials } from '../features/partners/partnerAuthSlice';

const baseUrl = config.BASE_URL;

const getParams = arg => {
  const params = {};

  const validParams = [
    'postcode',
    'housemates',
    'bill_payers',
    'address_id',
    'line1',
    'city',
    'coupon',
    'property_id',
  ];

  validParams.forEach(key => {
    if (arg[key]) {
      params[key] = arg[key];
    }
  });

  params['is_student'] = arg['is_student'] ? 1 : 0; // pass laravel request validation as string params fails

  return params;
};

const baseQuery = fetchBaseQuery({
  baseUrl: baseUrl,
  prepareHeaders: (headers, { getState }) => {
    headers.set('Access-Control-Allow-Origin', '*');
    headers.set(
      'Access-Control-Allow-Methods',
      'GET, POST, PUT,PATCH, DELETE, OPTIONS'
    );
    const partnerToken = getState().partnerAuth.partnerToken;
    if (partnerToken) {
      headers.set('PublicToken', partnerToken);
    }

    return headers;
  },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    const refreshResult = await baseQuery(
      `open/partners/${args.partnerNameUrl}`,
      api,
      extraOptions
    );
    if (refreshResult.data) {
      const partner = refreshResult.data?.data;
      api.dispatch(
        setCredentials({ partner, partnerPublicToken: partner?.public_token })
      );
      result = await baseQuery(args, api, extraOptions);
    }
  }
  return result;
};

export const homeboxService = createApi({
  reducerPath: 'bbAPI',
  baseQuery: baseQueryWithReauth,
  endpoints: builder => ({
    getPartnerPublicToken: builder.query({
      query: arg => {
        const { partnerNameUrl } = arg;
        return {
          url: `open/partners/${partnerNameUrl}`,
        };
      },
      transformResponse: response => {
        return response?.data;
      },
    }),

    findAddressByPostcode: builder.query({
      query: arg => {
        const { postCode } = arg;
        return {
          url: `open/address/find/${postCode}`,
        };
      },
      transformResponse: response => response?.data,
    }),

    // TODO: Implement full property lookup.
    // getPropertyAddress: builder.mutation({
    //   query: arg => {
    //     const { address } = arg;
    //     return {
    //       url: `open/property`,
    //       method: 'POST',
    //       body: address,
    //     };
    //   },
    //   transformResponse: response => response?.data,
    // }),

    // partner/package/tenant/quote
    getTenantPackage: builder.query({
      query: arg => {
        const params = getParams(arg);
        return {
          url: `open/packages`,
          params,
        };
      },
      transformResponse: response => response?.data,
    }),

    // partner/package/bills-included/quote
    getCustomQuote: builder.query({
      query: arg => {
        const params = getParams(arg);
        return {
          url: `open/quote`,
          params,
        };
      },
    }),

    verifyCoupon: builder.query({
      query: arg => {
        const { coupon } = arg;
        return {
          url: `open/coupon/${coupon}`,
        };
      },
    }),

    createPartnerOrder: builder.mutation({
      query: data => {
        return {
          url: `open/order`,
          method: 'POST',
          body: data,
        };
      },
      // transformResponse: (response) => response?.data,
    }),

    addBillPayerToTenantOrder: builder.mutation({
      query: data => {
        const { orderId } = data;

        let args = {
          url: `open/order/${orderId}/tenant`,
          method: 'POST',
          body: data,
        };

        if (data.precognition) {
          args.headers = {
            Precognition: true,
          };
        }

        return args;
      },
    }),

    updateBillPayerPaymentDetails: builder.mutation({
      query: data => {
        const { orderId, userId } = data;
        return {
          url: `open/order/${orderId}/tenant/${userId}/mandate`,
          method: 'PATCH',
          body: data,
        };
      },
    }),

    inviteBillPayerToTenantOrder: builder.mutation({
      query: data => {
        const { orderId } = data;
        data['order_id'] = orderId;
        return {
          url: `open/order/${orderId}/invite`,
          method: 'POST',
          body: data,
        };
      },
    }),

    getBillPayersForTenantOrder: builder.query({
      query: arg => {
        const { orderId } = arg;
        return {
          url: `open/order/${orderId}/tenant`,
        };
      },
      transformResponse: response => response?.data,
    }),

    getInvitedBillPayersForTenantOrder: builder.query({
      query: arg => {
        const { orderId } = arg;
        return {
          url: `open/order/${orderId}/invite`,
        };
      },
      transformResponse: response => response?.data,
    }),

    checkForConflictingTenancies: builder.query({
      query: arg => {
        return {
          url: 'open/order/conflict',
          params: arg,
        };
      },
    }),

    getLead: builder.query({
      query: arg => {
        const { leadId } = arg;

        return {
          url: `open/leads/${leadId}`,
        };
      },

      transformResponse: response => response?.data,
    }),

    getPropertyWithRealtimeProviders: builder.query({
      query: arg => {
        const { addressId, propertyId } = arg;

        return {
          url: `open/property/providers`,

          params: {
            ...(addressId
              ? { address_id: addressId }
              : { property_id: propertyId }),
          },
        };
      },
    }),

    findAddressByTextSearch: builder.query({
      query: ({ address }) => {
        return {
          url: `open/address/query/${address}`,
        };
      },
      transformResponse: response => [response],
    }),

    updateLead: builder.mutation({
      query: data => {
        const { leadId } = data;

        return {
          url: `open/leads/${leadId}`,
          method: 'PATCH',
          body: data,
        };
      },
    }),

    updateHousehold: builder.mutation({
      query: data => {
        const { orderId } = data;

        return {
          url: `open/order/${orderId}/household`,
          method: 'PATCH',
          body: data,
        };
      },
    }),

    createProperty: builder.mutation({
      query: data => {
        return {
          url: `open/property`,
          method: 'POST',
          body: data,
        };
      },
    }),

    getAddressByExternalId: builder.query({
      query: externalId => {
        return {
          url: `open/address/by-id/${externalId}`,
        };
      },
    }),
  }),
});

export const {
  useGetPartnerPublicTokenQuery,
  useFindAddressByPostcodeQuery,
  useGetTenantPackageQuery,
  useGetCustomQuoteQuery,
  useCreatePartnerOrderMutation,
  useAddBillPayerToTenantOrderMutation,
  useInviteBillPayerToTenantOrderMutation,
  useGetBillPayersForTenantOrderQuery,
  useGetInvitedBillPayersForTenantOrderQuery,
  useLazyVerifyCouponQuery,
  useUpdateBillPayerPaymentDetailsMutation,
  useLazyCheckForConflictingTenanciesQuery,
  useGetLeadQuery,
  useGetPropertyWithRealtimeProvidersQuery,
  useLazyFindAddressByTextSearchQuery,
  useUpdateLeadMutation,
  useUpdateHouseholdMutation,
  useCreatePropertyMutation,
  useLazyGetAddressByExternalIdQuery,
} = homeboxService;
