import React, { useEffect, Fragment } from 'react';
import { Button } from 'homebox-ui';
import { MdArrowForward } from 'react-icons/md';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { useFormikContext } from 'formik';
import BillCard from '../components/BillCard';
import GroupBillCard from '../components/GroupBillCard';
import BillsLoadingSkeleton from '../components/Skeletons/BillsLoadingSkeleton';
import MobileNavControl from '../components/MobileNavControl';
import { toast } from 'react-hot-toast';
import { AlertWarning } from '../components/AlertCard';
import { selectDefaultBill } from '../utils';
import { useExperiment } from '@marvelapp/react-ab-test';

export default function SetupBill() {
  const ENERGY_GROUP_NAME = 'Renewable Energy';
  const INTERNET_GROUP_NAME = 'Internet';

  const {
    rate,
    setRate,
    serviceBill,
    coupon,
    setCoupon,
    couponApplied,
    setCouponApplied,
    essentials,
    extras,
    perks,
    quoteDataLoading,
    isLoading,
    setFetchQuote,
    fetchingQuote,
  } = useOutletContext();

  const { nameUrl } = useParams();
  const { selectVariant } = useExperiment('EXP_EnergyBill');

  const isMonthly = rate === 'monthly';

  const { values, setFieldValue, setValues } = useFormikContext();
  const noOfBillPayers = values?.bill_payers;
  const noOfHousemates = values?.total_occupants;

  const removeDuplicates = data => {
    const key = 'group_id';
    return [...new Map(data.map(item => [item[key], item])).values()];
  };

  // select a bill
  const selectBill = payload => {
    const selectedBills = [...values?.billSelected, payload];
    setFieldValue('billSelected', selectedBills);
  };

  // remove bill from selections
  const deselectBill = payload => {
    let allBills = values?.billSelected;
    let idx = allBills?.findIndex(bill => bill?.id === payload);

    if (idx !== -1) {
      allBills.splice(idx, 1);
    }
    setFieldValue('billSelected', [...allBills]);
  };

  const isBillSelected = (id, isPerk = false) => {
    let allSelected = isPerk ? values?.perks : values?.billSelected;

    for (let i = 0; i < allSelected?.length; i++) {
      if (allSelected?.[i]?.id === id) {
        return true;
      }
    }
    return false;
  };

  const getSelectedBillFromGroup = groupId => {
    let allSelected =
      values?.billSelected?.length > 0 ? values?.billSelected : [];

    for (let i = 0; i < allSelected?.length; i++) {
      if (allSelected?.[i]?.group_id === groupId) {
        return allSelected[i];
      }
    }
    return {};
  };

  const toggleBillSelection = (id, payload) => {
    if (isBillSelected(id)) {
      deselectBill(id);
    } else {
      selectBill(payload);
    }
  };

  const isProfessionalTenancy =
    values?.household_type === 'professional-tenant';

  const energyBillSelected = payload => {
    if (typeof payload === 'boolean') {
      setFieldValue('energyBillSelected', payload);
    }
  };

  const autoSelectEssentialsAndPerks = () => {
    let autoSelectedBills = [];
    const autoSelectedPerks = [];
    let energyBills = [];
    let internetBills = [];

    const add_ons =
      values?.billSelected.length > 0
        ? values?.billSelected.map(x => x?.id)
        : [];

    const selectedPerks =
      values?.perks.length > 0 ? values?.perks.map(x => x?.id) : [];
    const allInternetBills =
      extras.length > 0 ? extras.filter(({ name }) => name === 'Internet') : [];

    if (essentials?.length > 0 && values?.billSelected.length < 1) {
      //TODO: refactor to set is_essential to true for internet bills
      [...essentials, ...allInternetBills]?.forEach(payload => {
        // refactor: Figure a way to pass bill reference as it's much cleaner
        switch (payload?.bills?.[0]?.group_name) {
          case ENERGY_GROUP_NAME:
            energyBills = payload?.bills;
            break;
          case INTERNET_GROUP_NAME:
            internetBills = payload?.bills;
            break;
          default:
            if (!add_ons.includes(payload?.bills?.[0]?.id)) {
              autoSelectedBills.push(payload?.bills?.[0]);
            }
        }
      });
    }

    if (perks?.length > 0 && values?.perks.length < 1) {
      perks?.forEach(payload => {
        if (!selectedPerks.includes(payload?.bills?.[0]?.id)) {
          autoSelectedPerks.push(payload?.bills?.[0]);
        }
      });
    }

    const variant = selectVariant({
      A: 'Unlimited',
      B: 'PAYG',
    });

    // auto-select unlimited energy bill or select other
    if (energyBills.length > 0) {
      const unlimitedEnergyBill = selectDefaultBill(energyBills, null, variant);
      autoSelectedBills.push(unlimitedEnergyBill);
    }

    if (internetBills.length > 0) {
      const internetBill = selectDefaultBill(internetBills, noOfHousemates);
      autoSelectedBills.push(internetBill);
    }

    setValues(prev => {
      return {
        ...prev,
        billSelected: removeDuplicates([
          ...prev?.billSelected,
          ...autoSelectedBills,
        ]),
        perks: [...prev?.perks, ...autoSelectedPerks],
        energyBills: energyBills.length,
        energyBillSelected: energyBills.length > 0,
      };
    });
  };

  useEffect(() => {
    autoSelectEssentialsAndPerks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [essentials, perks]);

  const navigate = useNavigate();

  const proceedToNextStep = async () => {
    const energySelected =
      values?.billSelected?.length > 0
        ? values?.billSelected?.some(
            bill => bill.group_name === ENERGY_GROUP_NAME
          )
        : false;

    if (!energySelected) {
      toast.custom(t => (
        <AlertWarning title={'Energy plan must be selected'} currentToast={t} />
      ));
      return;
    }

    // emitWin();

    navigate(`/${nameUrl}/account-setup`);
  };

  return (
    <div className='relative pb-64 lg:pb-0'>
      <div className='flex flex-col'>
        <h4 className='text-p-strong sm:text-h4 text-typography-primary'>
          Create your bills package
        </h4>
        <p className='text-typography-secondary mt-1 text-sm font-normal'>
          Get your free bills quote by choosing from the list of bills below.
          The amounts shown are based on {noOfBillPayers} bill payer
          {Number(noOfBillPayers) > 1 && 's'} living at this property.
        </p>
        {isProfessionalTenancy && (
          <p className='text-typography-secondary mt-1 text-sm font-normal'>
            Once you have signed up, you may add a council tax bill to your
            package. However, you will first need to register with your local
            council and upload your initial bill from them. We can take things
            from there.
          </p>
        )}
        <div className='flex max-w-2xl flex-col space-y-4 py-8 sm:space-y-[8px]'>
          {!isLoading && !quoteDataLoading && essentials?.length > 0 ? (
            essentials?.map((bill, idx) => (
              <Fragment key={`${bill?.name}-${idx}`}>
                {bill?.bills?.length === 1 ? (
                  <BillCard
                    data={bill}
                    toggleBillSelection={toggleBillSelection}
                    isBillSelected={isBillSelected}
                    isMonthly={isMonthly}
                    noOfHousemates={noOfHousemates}
                    noOfBillPayers={noOfBillPayers}
                    energyBillSelected={energyBillSelected}
                  />
                ) : (
                  <GroupBillCard
                    data={bill}
                    toggleBillSelection={toggleBillSelection}
                    isBillSelected={isBillSelected}
                    isMonthly={isMonthly}
                    getSelectedBillFromGroup={getSelectedBillFromGroup}
                    noOfHousemates={noOfHousemates}
                    noOfBillPayers={noOfBillPayers}
                  />
                )}
              </Fragment>
            ))
          ) : (
            <BillsLoadingSkeleton />
          )}
        </div>
        {extras?.length > 0 && (
          <>
            <div className='bg-main-linear-2 relative mt-8 pt-[2px]'>
              <div className='text-typography-primary  bg-neutral-100 px-6 py-2 text-lg uppercase'>
                Extras
              </div>
            </div>
            <div className='flex max-w-2xl flex-col space-y-4 py-8 sm:space-y-[8px]'>
              {!isLoading && !quoteDataLoading ? (
                extras?.length > 0 &&
                extras?.map((bill, idx) => (
                  <Fragment key={`${idx}-${bill?.name}`}>
                    {bill?.bills?.length === 1 ? (
                      <BillCard
                        data={bill}
                        toggleBillSelection={toggleBillSelection}
                        isBillSelected={isBillSelected}
                        isMonthly={isMonthly}
                        noOfHousemates={noOfHousemates}
                        noOfBillPayers={noOfBillPayers}
                      />
                    ) : (
                      <GroupBillCard
                        data={bill}
                        toggleBillSelection={toggleBillSelection}
                        isBillSelected={isBillSelected}
                        isMonthly={isMonthly}
                        getSelectedBillFromGroup={getSelectedBillFromGroup}
                        noOfHousemates={noOfHousemates}
                        noOfBillPayers={noOfBillPayers}
                      />
                    )}
                  </Fragment>
                ))
              ) : (
                <BillsLoadingSkeleton />
              )}
            </div>
          </>
        )}
        {/* perks */}
        {perks?.length > 0 && (
          <>
            <div className='bg-main-linear-4 relative mt-8 pt-[2px]'>
              <div className='text-typography-primary  bg-neutral-100 px-6 py-2 text-lg uppercase'>
                Perks
              </div>
            </div>
            <div className='flex max-w-2xl flex-col space-y-4 py-8 sm:space-y-[8px]'>
              {!isLoading && !quoteDataLoading ? (
                perks?.length > 0 &&
                perks?.map((bill, idx) => (
                  <BillCard
                    key={`${bill?.name}-${idx}`}
                    data={bill}
                    toggleBillSelection={toggleBillSelection}
                    isBillSelected={isBillSelected}
                    isMonthly={isMonthly}
                    isPerk
                    noOfHousemates={noOfHousemates}
                    noOfBillPayers={noOfBillPayers}
                  />
                ))
              ) : (
                <BillsLoadingSkeleton />
              )}
            </div>
          </>
        )}
        <div className='mx-auto mt-8 hidden w-full max-w-sm flex-col space-y-4 lg:flex'>
          <Button
            variant='primary'
            size={'cta'}
            className='font-semibold'
            onClick={proceedToNextStep}
            id='submit-addons'
            endIcon={<MdArrowForward className='ml-3 h-5 w-5 ' />}
          >
            Continue
          </Button>
        </div>
      </div>
      <MobileNavControl
        serviceBill={serviceBill}
        rate={rate}
        setRate={setRate}
        coupon={coupon}
        setCoupon={setCoupon}
        couponApplied={couponApplied}
        setCouponApplied={setCouponApplied}
        setFetchQuote={setFetchQuote}
        fetchingQuote={fetchingQuote}
      >
        <div className='border- mx-auto flex w-full max-w-sm flex-col space-y-4'>
          <Button
            variant='primary'
            size={'cta'}
            className='font-semibold'
            onClick={proceedToNextStep}
            id='submit-addons'
            endIcon={<MdArrowForward className='ml-3 h-5 w-5 ' />}
          >
            Continue
          </Button>
        </div>
      </MobileNavControl>
    </div>
  );
}
