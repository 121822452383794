import React, { useEffect } from 'react';
import {
  Button,
  Label,
  Input,
  Checkbox,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  SelectGroup,
  ScrollArea,
  DatePicker,
  RadioGroup,
  RadioGroupItem,
} from 'homebox-ui';
import { MdMail, MdArrowForward } from 'react-icons/md';
import { useOutletContext, useNavigate, useParams } from 'react-router-dom';
import { ErrorMessage, useFormikContext } from 'formik';
import { format, subYears, addMonths, addYears } from 'date-fns';
import { isValidDate, dateFromStartDate, tenancyLengths } from '../utils';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import MobileNavControl from '../components/MobileNavControl';
import { useLazyCheckForConflictingTenanciesQuery } from '../app/services/homeboxAPI';
import { toast } from 'react-hot-toast';

export default function AccountConfirmation() {
  const {
    values,
    handleChange,
    setFieldValue,
    isSubmitting,
    setTouched,
    touched,
    validateForm,
    submitForm,
    handleBlur,
  } = useFormikContext();

  const {
    rate,
    setRate,
    serviceBill,
    coupon,
    setCoupon,
    couponApplied,
    setCouponApplied,
    setMinEndDate,
    IS_TEST_ENV,
  } = useOutletContext();

  const {
    first_name,
    last_name,
    phone_number,
    email,
    birthday,
    university_year,
    terms_of_service,
  } = values;

  const navigate = useNavigate();
  const { nameUrl } = useParams();

  let maxDob = new Date();
  maxDob = new Date(maxDob.setFullYear(maxDob.getFullYear() - 18)).setMonth(12);

  // rolling monthly tenancy end date as 31st December 2050
  const rollingEndDate = new Date(2050, 11, 31);

  const [
    checkConflicts,
    {
      data: conflictData,
      isLoading: conflictLoading,
      error: conflictError,
      isSuccess: conflictSuccess,
      isFetching: conflictFetching,
    },
  ] = useLazyCheckForConflictingTenanciesQuery();

  const handleSubmit = async () => {
    const validatedForm = await validateForm();

    setTouched({
      ...touched,
      first_name: true,
      last_name: true,
      phone_number: true,
      email: true,
      birthday: true,
      university_year: true,
      terms_of_service: true,
      tenancy_end: true,
      tenancy_start: true,
      tenancy_length: true,
    });

    if (Object.keys(validatedForm).length > 0) {
      return;
    }

    setFieldValue('tenants.0', { first_name, last_name, phone_number });
    if (IS_TEST_ENV) {
      return navigate(`/${nameUrl}/bill-payers`);
    }

    if (
      (values?.property_id || values.address?.external_id) &&
      values?.tenancy_start &&
      values?.tenancy_end
    ) {
      await checkConflicts({
        ...(values?.property_id
          ? { property_id: values.property_id }
          : { address_id: values.address.external_id }),
        tenancy_start: format(values.tenancy_start, 'yyyy-MM-dd'),
        tenancy_end: format(values.tenancy_end, 'yyyy-MM-dd'),
      });
    } else {
      submitForm();
    }
  };

  const handleTenancyLengthChange = val => {
    setFieldValue('tenancy_length', val);

    if (values.tenancy_start) {
      // set the tenancy end date
      switch (val) {
        case 'other':
          setFieldValue(
            'tenancy_end',
            addYears(new Date(values.tenancy_start), 1)
          );
          break;
        case 'rolling':
          setFieldValue('tenancy_end', new Date(rollingEndDate));
          break;
        default:
          setFieldValue(
            'tenancy_end',
            addMonths(new Date(values.tenancy_start), Number(val))
          );
          break;
      }
    }
  };

  useEffect(() => {
    if (conflictFetching) return;

    if (conflictData || conflictSuccess) {
      submitForm();
    }

    if (conflictError) {
      if (IS_TEST_ENV) return navigate(`/${nameUrl}/bill-payers`);
      conflictError?.status === 409
        ? navigate(`/${nameUrl}/conflict`, {
            state: {
              conflictData: conflictError.data,
            },
          })
        : toast.error('Something went wrong, please try again later');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conflictFetching, IS_TEST_ENV]);

  return (
    <div className='relative min-h-screen pb-64 lg:pb-0'>
      <div className='flex flex-col'>
        <h4 className='text-p-strong sm:text-h4 text-typography'>
          Confirm your account details
        </h4>
        <div className='@md:grid-cols-2 @md:gap-8 @md:space-y-0 grid grid-cols-1 space-y-6 pt-8'>
          <div className='@md:max-w-xs  w-full'>
            <Label htmlFor='first_name'>First Name</Label>
            <Input
              name='first_name'
              value={first_name}
              onChange={handleChange}
              id='first_name'
              placeholder='Enter first name'
              type='text'
            />
            <ErrorMessage
              component={'span'}
              className='text-error text-xs'
              name='first_name'
            />
          </div>

          <div className='@md:max-w-xs w-full'>
            <Label htmlFor='last_name'>Last Name</Label>
            <Input
              name='last_name'
              value={last_name}
              onChange={handleChange}
              id='last_name'
              placeholder='Enter last name'
              type='text'
            />
            <ErrorMessage
              component={'span'}
              className='text-error text-xs'
              name='last_name'
            />
          </div>
          <div className='@md:max-w-xs  w-full'>
            <Label htmlFor='phone_number'>Your Mobile Number</Label>
            <PhoneInput
              defaultCountry='GB'
              placeholder='e.g. 7700 900123'
              value={phone_number}
              onChange={val => {
                setFieldValue('phone_number', val);
              }}
              id='phone_number'
            />
            {phone_number && (
              <span className='text-error text-xs'>
                {phone_number
                  ? isValidPhoneNumber(phone_number)
                    ? undefined
                    : 'Invalid phone number'
                  : 'Phone number required'}
              </span>
            )}
            <ErrorMessage
              component={'span'}
              className='text-error text-xs'
              name='phone_number'
            />
          </div>

          <div className='@md:max-w-xs  w-full'>
            <Label htmlFor='email'>Email</Label>
            <Input
              id='email'
              startIcon={<MdMail />}
              placeholder='Enter email address'
              type='email'
              name='email'
              value={email}
              onChange={handleChange}
            />
            <ErrorMessage
              component={'span'}
              className='text-error text-xs'
              name='email'
            />
          </div>

          <div id='birthday' className='@md:max-w-xs  w-full'>
            <Label htmlFor='dob'>Date of Birth</Label>
            {/* Refactor: Make year component select field <div className='flex space-x-1'>
            <Input placeholder='Day' maxLength={2} type='text' />
            <Input placeholder='Month' maxLength={2} type='text' />
            <Input placeholder='Year' maxLength={2} type='text' />
          </div> */}
            <DatePicker
              label={'Choose birthday'}
              date={birthday}
              setDate={val => {
                if (val) {
                  setFieldValue('birthday', val);
                }
              }}
              fromDate={subYears(new Date(), 100)}
              toDate={maxDob}
              captionLayout='dropdown'
              defaultMonth={
                new Date(
                  ((birthday && birthday?.getFullYear()) || 1990) ??
                    maxDob.getFullYear(),
                  ((birthday && birthday?.getMonth()) || 0) ??
                    maxDob.getMonth(),
                  1
                )
              }
              required
            />
            <Input
              type='date'
              name='birthday'
              value={
                isValidDate(birthday)
                  ? format(new Date(birthday), 'yyyy-MM-dd')
                  : ''
              }
              onChange={e => {
                if (e.target.value) {
                  setFieldValue('birthday', new Date(e.target.value));
                }
              }}
              onBlur={e => {
                if (e.target.valueAsDate) {
                  setFieldValue('birthday', new Date(e.target.valueAsDate));
                }
              }}
              className='hidden'
              placeholder='YYYY-MM-DD'
            />
            <ErrorMessage
              component={'span'}
              className='text-error text-xs'
              name='birthday'
            />
          </div>
          {values?.household_type === 'student' && (
            <div className='w-full md:max-w-xs'>
              <Label htmlFor='university_year'>Your University Year</Label>
              <Select
                value={university_year || ''}
                onValueChange={value => {
                  setFieldValue('university_year', value);
                }}
                name='university_year'
              >
                <SelectTrigger className=''>
                  <SelectValue placeholder='Your University Year' />
                </SelectTrigger>
                <SelectContent id='university_year'>
                  <SelectGroup>
                    <ScrollArea className='h-[240px] w-full rounded-md'>
                      <SelectItem disabled value=''>
                        Choose
                      </SelectItem>
                      {Array(4)
                        .fill('')
                        .map((n, idx) => (
                          <SelectItem key={`${idx + 1}`} value={`${idx + 1}`}>
                            Year {idx + 1}
                          </SelectItem>
                        ))}
                      <SelectItem value='5'>Year 5+</SelectItem>
                    </ScrollArea>
                  </SelectGroup>
                </SelectContent>
              </Select>
              <ErrorMessage
                component={'span'}
                className='text-error'
                name='university_year'
              />
            </div>
          )}
          <div className='w-full sm:max-w-xs'></div>
          <div id='tenancy_start' className='w-full sm:max-w-xs'>
            <Label htmlFor='tenancy_start'>Tenancy Start Date</Label>
            <DatePicker
              label='Tenancy start date'
              date={values?.tenancy_start}
              setDate={val => {
                if (val) {
                  setFieldValue('tenancy_start', val);
                  // minimum end date is 6 months after start date
                  setMinEndDate(dateFromStartDate(new Date(val), 6));
                }
              }}
              defaultMonth={
                new Date(
                  values?.tenancy_start
                    ? values?.tenancy_start?.getFullYear()
                    : new Date().getFullYear(),
                  values?.tenancy_start
                    ? values?.tenancy_start?.getMonth()
                    : new Date().getMonth(),
                  1
                )
              }
            />
            <Input
              type='date'
              name='tenancy_start'
              value={
                values?.tenancy_start && isValidDate(values?.tenancy_start)
                  ? format(new Date(values?.tenancy_start), 'yyyy-MM-dd')
                  : ''
              }
              onChange={e => {
                if (e.target.valueAsDate) {
                  setFieldValue(
                    'tenancy_start',
                    new Date(e.target.valueAsDate)
                  );
                  setMinEndDate(
                    dateFromStartDate(new Date(e.target.valueAsDate), 3)
                  );
                }
              }}
              onBlur={e => {
                if (e.target.valueAsDate) {
                  setFieldValue(
                    'tenancy_start',
                    new Date(e.target.valueAsDate)
                  );
                }
                handleBlur(e);
              }}
              className='hidden'
              placeholder='YYYY-MM-DD'
            />
            <ErrorMessage
              component='span'
              className='text-error text-xs'
              name='tenancy_start'
            />
          </div>
          <div className='flex items-center space-x-2'>
            <Checkbox
              id='move_in_start'
              name='move_in_start'
              onCheckedChange={val => setFieldValue('move_in_start', val)}
              defaultChecked={values.move_in_start}
              checked={values.move_in_start}
            />
            <Label htmlFor='move_in_start'>
              We move in on the tenancy start date
            </Label>
          </div>

          {!values.move_in_start && (
            <div className='w-full sm:max-w-xs'>
              <Label htmlFor='tenancy_move_in'>Move In Date</Label>
              <DatePicker
                label='Move in date'
                date={values.tenancy_move_in}
                setDate={val => {
                  if (val) {
                    setFieldValue('tenancy_move_in', val);
                  }
                }}
                defaultMonth={
                  new Date(
                    values?.tenancy_move_in
                      ? values?.tenancy_move_in?.getFullYear()
                      : new Date().getFullYear(),
                    values?.tenancy_move_in
                      ? values?.tenancy_move_in?.getMonth()
                      : new Date().getMonth(),
                    1
                  )
                }
              />
              <Input
                type='date'
                name='tenancy_move_in'
                value={
                  isValidDate(values.tenancy_move_in)
                    ? format(new Date(values.tenancy_move_in), 'yyyy-MM-dd')
                    : ''
                }
                onChange={e => {
                  if (e.target.valueAsDate) {
                    setFieldValue(
                      'tenancy_move_in',
                      new Date(e.target.valueAsDate)
                    );
                  }
                }}
                id='tenancy_move_in'
                className='hidden'
                onBlur={e => {
                  if (e.target.valueAsDate) {
                    setFieldValue(
                      'tenancy_move_in',
                      new Date(e.target.valueAsDate)
                    );
                  }
                  handleBlur(e);
                }}
                placeholder='YYYY-MM-DD'
              />
              <p className='text-typography-primary py-2 text-sm'>
                If this date changes you just need to contact us and let us know
                as soon as possible
              </p>
              <ErrorMessage
                component='span'
                className='text-error text-xs'
                name='tenancy_move_in'
              />
            </div>
          )}
          {values.tenancy_start && (
            <div className='col-span-full'>
              <Label className='font-sans'>How long is your tenancy?</Label>
              <RadioGroup
                className='mt-2 flex w-full flex-wrap'
                name='tenancy_length'
                value={values.tenancy_length}
                onValueChange={value => {
                  handleTenancyLengthChange(value);
                }}
                onBlur={handleBlur}
              >
                {tenancyLengths.map(({ value, label }) => (
                  <Label
                    key={`tenancy-length-${value}`}
                    htmlFor={value}
                    className='hover:bg-button-primary-hover ring-primary-lighter border-neutral hover:bg-accent [&:has([data-state=checked])]:border-primary [&:has([data-state=checked])]:text-typography-inverted [&:has([data-state=checked])]:bg-background-dark h-10 rounded-[32px] border bg-neutral-100 px-2 py-[10px] text-center font-sans text-sm font-medium text-neutral-600 focus-visible:outline-none focus-visible:ring-4 focus-visible:ring-offset-0 peer-disabled:bg-neutral-100 sm:px-8 [&:has([data-state=checked])]:hover:bg-neutral-200'
                  >
                    <RadioGroupItem
                      value={value}
                      id={value}
                      className='sr-only'
                    />
                    {label}
                  </Label>
                ))}
              </RadioGroup>
              <ErrorMessage
                component='span'
                className='text-error text-xs'
                name='tenancy_length'
              />
            </div>
          )}
          {values.tenancy_length === 'other' && (
            <div className='w-full sm:max-w-xs'>
              <Label htmlFor='tenancy_end'>Specify when the tenancy ends</Label>
              <DatePicker
                label='Tenancy end date'
                date={values?.tenancy_end}
                setDate={val => {
                  if (val) {
                    setFieldValue('tenancy_end', val);
                  }
                }}
                fromDate={
                  values?.tenancy_start ? new Date(values.tenancy_start) : null
                }
                defaultMonth={
                  new Date(
                    values?.tenancy_end
                      ? values?.tenancy_end?.getFullYear()
                      : new Date().getFullYear(),
                    values?.tenancy_end
                      ? values?.tenancy_end?.getMonth()
                      : new Date().getMonth(),
                    1
                  )
                }
              />
              <Input
                type='date'
                name='tenancy_end'
                value={
                  isValidDate(values.tenancy_end)
                    ? format(new Date(values.tenancy_end), 'yyyy-MM-dd')
                    : ''
                }
                onChange={e => {
                  if (e.target.valueAsDate) {
                    setFieldValue(
                      'tenancy_end',
                      new Date(e.target.valueAsDate)
                    );
                  }
                }}
                id='tenancy_end'
                onBlur={e => {
                  if (e.target.valueAsDate) {
                    setFieldValue(
                      'tenancy_end',
                      new Date(e.target.valueAsDate)
                    );
                  }
                  handleBlur(e);
                }}
                className='hidden'
                placeholder='YYYY-MM-DD'
              />
              <ErrorMessage
                component='span'
                className='text-error text-xs'
                name='tenancy_end'
              />
            </div>
          )}
        </div>

        <div className='mt-8 flex flex-col space-y-5'>
          <div>
            <div className='flex h-5 items-center space-x-2'>
              <Checkbox
                id='terms_of_service'
                name='terms_of_service'
                defaultChecked={terms_of_service}
                checked={terms_of_service}
                onCheckedChange={val => setFieldValue('terms_of_service', val)}
              />

              <Label
                htmlFor='terms_of_service'
                className='text-typography cursor-pointer text-base font-medium'
              >
                I have read and agree to the
                <a
                  href='https://www.homebox.co.uk/terms-of-service-residential'
                  target='_blank'
                  rel='noreferrer noopener'
                  className='ml-2 underline'
                >
                  Terms of Service
                </a>
              </Label>
            </div>
            <ErrorMessage
              component={'span'}
              className='text-error text-xs'
              name='terms_of_service'
            />
          </div>
        </div>
        <div className='mx-auto mt-8 hidden w-full max-w-sm flex-col space-y-4 lg:flex'>
          <Button
            variant='primary'
            size={'cta'}
            className='font-semibold'
            isLoading={isSubmitting || conflictLoading}
            id='setup-account'
            endIcon={<MdArrowForward className='ml-3 h-5 w-5 ' />}
            onClick={handleSubmit}
          >
            Continue
          </Button>
        </div>
      </div>

      <MobileNavControl
        serviceBill={serviceBill}
        rate={rate}
        setRate={setRate}
        coupon={coupon}
        setCoupon={setCoupon}
        couponApplied={couponApplied}
        setCouponApplied={setCouponApplied}
      >
        <div className='mx-auto flex w-full max-w-sm flex-col'>
          <Button
            variant='primary'
            size={'cta'}
            className='font-semibold'
            isLoading={isSubmitting || conflictLoading}
            endIcon={<MdArrowForward className='ml-3 h-5 w-5 ' />}
            onClick={handleSubmit}
          >
            Continue
          </Button>
        </div>
      </MobileNavControl>
    </div>
  );
}
